import React from 'react';
import NavBar from 'cccisd-header';
import style from './style.css';

const Fortress = window.cccisd && window.cccisd.fortress;

export default () => {
    const actingRole = Fortress.user.acting.role.handle;
    const wpUrl = window.cccisd.env.wpUrl;

    const primaryNavLinks = [
        { url: wpUrl + '/about', label: 'About' },
        {
            url: wpUrl + '/programs',
            label: 'Programs',
            children: [
                { url: wpUrl + '/programs/choice/', label: 'CHOICE' },
                { url: wpUrl + '/programs/freetalk/', label: 'Free Talk' },
                { url: wpUrl + '/programs/micunay/', label: 'MICUNAY' },
                { url: wpUrl + '/programs/aware/', label: 'AWARE' },
            ],
        },
        { url: wpUrl + '/mi/resources/', label: 'Resources' },
        { url: wpUrl + '/faq/', label: 'FAQ' },
        { url: '/manage', label: 'Manage' },
    ];
    const primaryNavLinks2 = [
        { url: wpUrl + '/about', label: 'About' },
        {
            url: wpUrl + '/programs',
            label: 'Programs',
            children: [
                { url: wpUrl + '/programs/choice/', label: 'CHOICE' },
                { url: wpUrl + '/programs/freetalk/', label: 'Free Talk' },
                { url: wpUrl + '/programs/micunay/', label: 'MICUNAY' },
                { url: wpUrl + '/programs/aware/', label: 'AWARE' },
            ],
        },
        { url: wpUrl + '/mi/resources/', label: 'Resources' },
        { url: wpUrl + '/faq/', label: 'FAQ' },
    ];

    return (
        <>
            {actingRole === 'guest' && (
                <NavBar
                    containerClassName="container-fluid"
                    wrapperClassName={style.wrapper}
                    logoClassName={style.logo}
                    showAlertAction={false}
                    primaryNavLinks={primaryNavLinks2}
                    logoLink={wpUrl}
                />
            )}
            {actingRole === 'learner' && (
                <NavBar
                    containerClassName="container-fluid"
                    wrapperClassName={style.wrapper}
                    logoClassName={style.logo}
                    showAlertAction={false}
                    primaryNavLinks={primaryNavLinks2}
                />
            )}
            {actingRole === 'uberadmin' && (
                <NavBar
                    containerClassName="container-fluid"
                    wrapperClassName={style.wrapper}
                    logoClassName={style.logo}
                    showAlertAction={false}
                    primaryNavLinks={primaryNavLinks}
                    logoLink={wpUrl}
                />
            )}
        </>
    );
};
