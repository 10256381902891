import React from 'react';
import PropTypes from 'prop-types';
import NavBar from 'js/components/NavBar';
import Footer from 'cccisd-footer';
import style from './style.css';

export default class FixedLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        showFooter: PropTypes.bool,
    };

    static defaultProps = {
        className: 'container',
        showFooter: true,
    };

    render() {
        const { children, className, showFooter } = this.props;

        return (
            <div className={style.wrapper}>
                <div className={style.header}>
                    <NavBar className={this.props.className} />
                </div>

                <div className={style.body}>
                    <div className={className}>{children}</div>
                </div>

                {showFooter && (
                    <div className={style.footer}>
                        <Footer className={className} />
                    </div>
                )}
            </div>
        );
    }
}
