import React from 'react';
import style from './style.css';

import { RegisterLoginForm } from 'cccisd-laravel-nexus';

export default class RegisterPage extends React.Component {
    registerProps = {
        title: 'Register',
        role: 'learner',
        showPasswordFields: true,
    };

    loginProps = {
        title: 'Login',
        showPasswordToggle: true,
    };

    render() {
        return (
            <div className={style.page}>
                <RegisterLoginForm
                    registerProps={this.registerProps}
                    loginProps={this.loginProps}
                />
            </div>
        );
    }
}
