import React, { useState, useEffect } from 'react';
import _get from 'lodash/get';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { useParams, Redirect } from 'react-router-dom';
import deploymentQuery from './deployment.graphql';

const Fortress = window.cccisd.fortress;
const Appdefs = window.cccisd.appDefs;
const Boilerplate = window.cccisd.boilerplate;

const Course = () => {
    const { endpoint } = useParams();
    if (!Fortress.auth() || !endpoint) {
        return <Redirect to="/register" />;
    }

    const [isLoading, setIsLoading] = useState(true);
    const [isEndpointValid, setIsEndpointValid] = useState(null);
    const [isRegistrationComplete, setIsRegistrationComplete] = useState(null);

    useEffect(() => {
        async function checkDeploymentExists() {
            let registrationHandle;
            try {
                registrationHandle = Appdefs.pawn.roles.find(
                    r => r.handle === Fortress.user.acting.role.handle
                ).siteRegistrationDCWHandle;
            } catch (e) {
                registrationHandle = '';
            }

            const resp = await client.query({
                query: deploymentQuery,
                variables: { endpoint, registrationHandle, actingPawnId: Fortress.user.acting.id },
                fetchPolicy: 'network-only',
            });

            const isExists = !!_get(resp, 'data.flows.deployment.deploymentId', null);
            const isRegistrationDcwComplete =
                !!_get(resp, 'data.flows.registrationDcw.assignmentProgress.completed', false) || false;
            setIsEndpointValid(isExists);
            setIsRegistrationComplete(isRegistrationDcwComplete);
            setIsLoading(false);
        }
        checkDeploymentExists();
    }, []);

    if (isLoading || isEndpointValid === null) {
        return <Loader loading />;
    }

    if (!isEndpointValid) {
        return <Redirect to="/" />;
    }

    if (!isRegistrationComplete && Fortress.user.acting.role.handle === 'learner') {
        let base = Boilerplate.url('');
        if (base.endsWith('/')) {
            base = base.slice(0, base.length - 1);
        }

        return <Redirect to={`/account/registrationSurvey?redirectUrl=${base}/course/${endpoint}`} />;
    }

    return <Redirect to={`/survey/${endpoint}`} />;
};

export default Course;
